import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox", "input"];

  updateSelection() {
    const selected = [];
    this.checkboxTargets.forEach((checkbox) => {
      if (checkbox.checked) {
        selected.push(checkbox.nextElementSibling.textContent.trim());
      }
    });
    // Join the selected items into a single string, and display them in the input field
    this.inputTarget.value = selected.join(", ");
  }
}
