// app/javascript/controllers/delivery_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["customField"];

  connect() {
    // Initial check if custom option was pre-selected
    this.toggleCustom();
  }

  toggleCustom(event) {
    const selectElement = event
      ? event.target
      : this.element.querySelector("select");
    const customField = document.getElementById("custom_est_delivery_field");

    if (selectElement.value === "Custom") {
      customField.style.display = "block";
    } else {
      customField.style.display = "none";
    }
  }
}
