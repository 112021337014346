import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "hours",
    "rate",
    "total",
    "totalProductionCost",
    "surveyCost",
    "surveyMarkup",
    "surveyTotal",
    "lidarFlightCost",
    "lidarFlightMarkup",
    "lidarFlightTotal",
    "imageryFlightCost",
    "imageryFlightMarkup",
    "imageryFlightTotal",
    "uavFlightCost",
    "uavFlightMarkup",
    "uavFlightTotal",
    "expediteTotal",
    "adminTotal",
    "projectTotal",
  ];

  connect() {
    this.updateTotals();
  }

  updateTotals() {
    let totalProductionCost = 0;

    this.hoursTargets.forEach((element, index) => {
      const hours = parseFloat(element.value) || 0;
      const rate = parseFloat(this.rateTargets[index].value) || 0;
      const total = hours * rate;

      this.totalTargets[index].value = total.toFixed(2);
      totalProductionCost += total;
    });

    this.totalProductionCostTarget.value = totalProductionCost.toFixed(2);

    const surveyCost = parseFloat(this.surveyCostTarget.value) || 0;
    const surveyMarkup = parseFloat(this.surveyMarkupTarget.value) || 0;
    const surveyTotal = surveyCost + surveyCost * (surveyMarkup / 100);
    this.surveyTotalTarget.value = surveyTotal.toFixed(2);

    const lidarFlightCost = parseFloat(this.lidarFlightCostTarget.value) || 0;
    const lidarFlightMarkup =
      parseFloat(this.lidarFlightMarkupTarget.value) || 0;
    const lidarFlightTotal =
      lidarFlightCost + lidarFlightCost * (lidarFlightMarkup / 100);
    this.lidarFlightTotalTarget.value = lidarFlightTotal.toFixed(2);

    const imageryFlightCost =
      parseFloat(this.imageryFlightCostTarget.value) || 0;
    const imageryFlightMarkup =
      parseFloat(this.imageryFlightMarkupTarget.value) || 0;
    const imageryFlightTotal =
      imageryFlightCost + imageryFlightCost * (imageryFlightMarkup / 100);
    this.imageryFlightTotalTarget.value = imageryFlightTotal.toFixed(2);

    const uavFlightCost = parseFloat(this.uavFlightCostTarget.value) || 0;
    const uavFlightMarkup = parseFloat(this.uavFlightMarkupTarget.value) || 0;
    const uavFlightTotal = uavFlightCost + uavFlightCost * (uavFlightMarkup / 100);
    this.uavFlightTotalTarget.value = uavFlightTotal.toFixed(2);

    const expediteTotal = parseFloat(this.expediteTotalTarget.value) || 0;

    // Calculate the initial project total without adminTotal
    const initialProjectTotal =
      totalProductionCost +
      surveyTotal +
      lidarFlightTotal +
      imageryFlightTotal +
      uavFlightTotal +
      expediteTotal;

    // Calculate adminTotal as 2.5% of initialProjectTotal
    const adminTotal = (initialProjectTotal * 0.025).toFixed(2);
    this.adminTotalTarget.value = adminTotal;

    // Calculate the final project total including adminTotal
    const finalProjectTotal = initialProjectTotal + parseFloat(adminTotal);
    this.projectTotalTarget.value = finalProjectTotal.toFixed(2);
  }
}
